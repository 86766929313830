// import {
// 	OBJLoader,
// 	MTLLoader
// } from "three-obj-mtl-loader";
import * as THREE from "three"; //三维
import {
	FBXLoader
} from 'three/examples/jsm/loaders/FBXLoader';
import {
	GLTFLoader
} from "three/examples/jsm/loaders/GLTFLoader";

import {
	OBJLoader
} from "three/examples/jsm/loaders/OBJLoader";
import {
	MTLLoader
} from "three/examples/jsm/loaders/MTLLoader";
// 加载obj,mtl文件
export function objloader(filename, path) {
	return new Promise(resolve => {
		var mtlLoader = new MTLLoader();
		//  初始化obj
		var objLoader = new OBJLoader();
		// 加载mtl文件
		mtlLoader.load(`/static/${filename}/${path}.mtl`, (mtl) => {
			// 初始化
			mtl.preload();
			// 加载贴图
			objLoader.setMaterials(mtl);
			objLoader.load(`/static/${filename}/${path}.obj`, (obj) => {
				obj.traverse(function(child) {
					if(child.material){
						child.material.side = THREE.DoubleSide;
					}
				});
				resolve({
					mtl,
					obj
				})
			})
		})

	});
}

// 加载fbx文件
export function fbxloader(filename, path) {
	return new Promise(resolve => {
		var loader = new FBXLoader();
		loader.load(`/static/${filename}/${path}.fbx`, (fbx) => {
			resolve(fbx)
		})
	});
}
// 加载gtlf文件
export function gltfloader(path) {
	return new Promise(resolve => {
		var loader = new GLTFLoader();
		loader.load(`/static/${path}.gltf`, (gltf) => {
			resolve(gltf)
		})
	});
}
