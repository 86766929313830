<template>
	<!-- 三维画布 -->
	<div class="draw" ref="draw" />
</template>

<script>
	import * as THREE from "three"; //三维
	import {
		OrbitControls
	} from "three/examples/jsm/controls/OrbitControls.js"; //控制器

	export default {
		name: "draw", // 三维画布
		props: {
			// // 传入模型函数
			initLoader: {
				type: [Function],
			},
			// 传入光源数组
			lightlist: {
				type: [Array],
				default: () => {
					return [{
						type: "AmbientLight", //光的类型
						color: 0xffffff, //光的颜色
						intensity: 2, //光照强度
						posiy: [0, 0, 0], //光照位置
					}, ];
				},
			},
			// 传入相机对象
			sightline: {
				type: [Object],
				default: () => {
					return {
						scale: 200, //视线比例
						type: "PerspectiveCamera", //相机的类型  PerspectiveCamera OrthographicCamera
						posiy: [0, 0, 0], //相机位置
					};
				},
			},
			// 传入渲染对象
			strain: {
				type: [Object],
				default: () => {
					return {
						color: 0x000000, //背景颜色
						Encoding: true, //色彩空间
						shadow: true, //渲染阴影
					};
				},
			},
			// 是否开启鼠标控制
			orbit: {
				type: [Boolean],
				default: true,
			},
		},
		data() {
			return {
				scene: null, //场景对象Scene
				camera: null, //相机对象
				draw: null, //节点对象
				renderer: null, //渲染器对象
				controls: null, //控件对象
				movetype: -1, //鼠标事件，默认-1（0点击，1移动
				isclick: null, //判断鼠标状态
			};
		},
		mounted() {
			// 初始化渲染
			this.init();
			// 初始化控制
			this.orbitControls();
			// 条添加事件
			this.addmeth();
			// 设置动画
			this.tweenUpdate();
		},
		methods: {
			init() {
				//  初始化场景对象
				this.initScene();
				// 初始化模型
				this.initLoader();
				// 初始化光源
				this.initLight();
				// 初始化相机
				this.initCamera();
				// 初始化渲染对象
				this.initRenderer();
				// 渲染操作
				this.animation();
			},
			// 初始化场景对象
			initScene() {
				// 初始化场景对象
				this.scene = new THREE.Scene();
				// 创建三维坐标系
				// var axesHelper = new THREE.AxesHelper(100);
				// this.scene.add(axesHelper);
			},
			// 初始化光源
			initLight() {
				// 遍历添加光源
				this.lightlist.forEach((item) => {
					var light = new THREE[item.type](item.color, item.intensity);
					light.position.set(item.posiy[0], item.posiy[1], item.posiy[2]); //点光源位置
					if (item.type === "DirectionalLight") {
						light.shadow.camera.near = 20; //产生阴影的最近距离
						light.shadow.camera.far = 200; //产生阴影的最远距离
						light.shadow.camera.left = -50; //产生阴影距离位置的最左边位置
						light.shadow.camera.right = 50; //最右边
						light.shadow.camera.top = 50; //最上边
						light.shadow.camera.bottom = -50; //最下面
						//这两个值决定使用多少像素生成阴影 默认512
						light.shadow.mapSize.height = 1024;
						light.shadow.mapSize.width = 1024;
						//告诉平行光需要开启阴影投射
						light.castShadow = true;
					}
					this.scene.add(light);
				});

				// const light1 = new THREE.DirectionalLight(0xffffff, 0.1);
				// light1.position.set(0, 0, 10);
				// this.scene.add(light1);
				// const light2 = new THREE.DirectionalLight(0xffffff, 0.1);
				// light2.position.set(0, 0, -10);
				// this.scene.add(light2);
				const light3 = new THREE.DirectionalLight(0xffffff, 0.1);
				light3.position.set(10, 0, 0);
				this.scene.add(light3);
				const light4 = new THREE.DirectionalLight(0xffffff, 0.1);
				light4.position.set(-10, 0, 0);
				this.scene.add(light4);
				const light5 = new THREE.DirectionalLight(0xffffff, 0.1);
				light5.position.set(0, 10, 0);
				this.scene.add(light5);
				const light6 = new THREE.DirectionalLight(0xffffff, 0.1);
				light6.position.set(5, 10, 0);
				this.scene.add(light6);
				const light7 = new THREE.DirectionalLight(0xffffff, 0.1);
				light7.position.set(0, 10, 5);
				this.scene.add(light7);
				const light8 = new THREE.DirectionalLight(0xffffff, 0.1);
				light8.position.set(0, 10, -5);
				this.scene.add(light8);
				const light9 = new THREE.DirectionalLight(0xffffff, 0.1);
				light9.position.set(-5, 10, 0);
				this.scene.add(light9);
			},
			// 初始化相机
			initCamera() {
				var k = this.$refs.draw.offsetWidth / this.$refs.draw.offsetHeight; //窗口宽高比
				// //创建相机对象
				if (this.sightline.type === "OrthographicCamera") {
					this.camera = new THREE.OrthographicCamera(
						-this.sightline.scale * k,
						this.sightline.scale * k,
						this.sightline.scale,
						-this.sightline.scale,
						1,
						1000
					);

				} else if (this.sightline.type === "PerspectiveCamera") {
					this.camera = new THREE.PerspectiveCamera(
					45,
					window.innerWidth / window.innerHeight,
					0.8,
					10000
				);
				}
				// // 红 绿 蓝
				// this.camera.position.set(
				// 	this.sightline.posiy[0],
				// 	this.sightline.posiy[1],
				// 	this.sightline.posiy[2]
				// ); //设置相机位置
				// this.camera.lookAt(this.scene.position); //设置相机方向(指向的场景对象)
				
				//将相机放到x:1000 , y:1000 , z:1000的位置
				this.camera.position.set(100, 200, 800);
				//设置相机的朝向，可以认为与相机镜头垂直的轴线应该和哪一个轴相交
				this.camera.up.set(0, 1, 0);
				// 设置相机位置(眼睛位置或者说相机篇拍照位置)
				// this.camera.position.set(500, 100, 0);
				//摄像机镜头指向的具体坐标位置
				this.camera.lookAt(0, 0, 0);
				// this.camera.zoom = 1;
				// this.camera.updateProjectionMatrix();



			},
			// 渲染器对象
			initRenderer() {
				//渲染器对象
				this.renderer = new THREE.WebGLRenderer({
					antialias: true
				});
				// 设置渲染区域尺寸
				this.renderer.setSize(
					this.$refs.draw.offsetWidth,
					this.$refs.draw.offsetHeight
				);
				// // three.js 的色彩空间渲染方式  【重要】
				if (this.strain.Encoding) {
					this.renderer.outputEncoding = THREE.sRGBEncoding;
				}
				if (this.strain.shadow) {
					// 开启渲染阴影
					this.renderer.shadowMap.enabled = true;
					this.renderer.hadowMapEnabled = true;
				}
				this.renderer.setClearColor(this.strain.color, 0.1); //设置背景颜色

				//body元素中插入canvas对象
				this.$refs.draw.appendChild(this.renderer.domElement);
			},
			// 执行渲染操作
			animation() {
				//执行渲染操作
				this.renderer.render(this.scene, this.camera);
				requestAnimationFrame(this.animation);
			},
			// 初始化控制
			orbitControls() {
				if (this.orbit) {
					this.controls = new OrbitControls(
						this.camera,
						this.renderer.domElement
					); //创建控件对象
				}
			},
			// 添加全局方法
			addmeth() {
				// 监听窗口尺寸变化
				window.addEventListener("resize", this.changeSize, false);
				// 监听鼠标按下
				window.addEventListener("mousedown", this.mouseDown, false);
				//  监听鼠标移动
				window.addEventListener("mousemove", this.mouseMove, false);
				// 监听鼠标弹起
				window.addEventListener("mouseup", this.mouseUp, false);
			},
			// 监听尺寸变化
			changeSize() {
				// 重置渲染器输出画布canvas尺寸
				this.renderer.setSize(
					this.$refs.draw.offsetWidth,
					this.$refs.draw.offsetHeight
				);
				var k = this.$refs.draw.offsetWidth / this.$refs.draw.offsetHeight; //窗口宽高比
				//重置相机投影的相关参数
				if (this.sightline.type === "OrthographicCamera") {
					this.camera.left = -this.sightline.scale * k;
					this.camera.right = this.sightline.scale * k;
					this.camera.top = this.sightline.scale;
					this.camera.bottom = -this.sightline.scale;
				} else if (this.sightline.type === "PerspectiveCamera") {
					camera.aspect = k;
				}
				// 如果相机的一些属性发生了变化，
				// 需要执行updateProjectionMatrix ()方法更新相机的投影矩阵
				this.camera.updateProjectionMatrix();
			},
			// 监听鼠标按下
			mouseDown(event) {
				this.isclick = {
					clientX: event.clientX,
					clientY: event.clientY,
				};
			},
			// 监听鼠标移动
			mouseMove(event) {
				if (!this.isclick) {
					if (this.$refs.draw) {
						var list = this.countmouse(event);
						// 传出鼠标移动事件
						this.$emit("change", "move", list);
					}
				}
			},
			// 监听鼠标弹起
			mouseUp(event) {
				// 如果相等说明是点击事件
				if (
					(this.isclick.clientX === event.clientX) &
					(this.isclick.clientY === event.clientY)
				) {
					// 传出点击事件
					this.isclick = null;
					if (this.$refs.draw) {
						var list = this.countmouse(event);
						this.$emit("change", "click", list);
					}
				} else {
					this.isclick = null;
				}
			},
			// 判断当前点击的事件
			countmouse(event) {
				let raycaster = new THREE.Raycaster();
				let mouse = new THREE.Vector2();
				//将鼠标点击位置的屏幕坐标转换成threejs中的标准坐标
				var dom = this.$refs.draw.getBoundingClientRect();
				if (
					event.clientX >= dom.left &&
					event.clientY >= dom.top &&
					event.clientX <= dom.left + this.$refs.draw.offsetWidth &&
					event.clientY <= dom.top + this.$refs.draw.offsetHeight
				) {
					mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
					mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
					// 通过鼠标点的位置和当前相机的矩阵计算出raycaster
					raycaster.setFromCamera(mouse, this.camera);
					// 获取raycaster直线和所有模型相交的数组集合
					var list = raycaster.intersectObjects(this.scene.children);
					if (list.length > 0) {
						return list;
					} else {
						return [];
					}
				} else {
					return [];
				}
			},
			// 设置动画
			tweenUpdate() {
				requestAnimationFrame(this.tweenUpdate);
				this.$tweener.update();
			},
			// 运动动画
			tweenobj(object, potion, time) {
				this.$tween.fade(
					object.position,
					potion,
					time,
					0,
					this.$tweener.Easing.Cubic.InOut
				);
			},
		},
	};
</script>

<style lang="less" scoped>
	// 三维画布
	.draw {
		width: 100%;
		height: 100%;
	}
</style>
