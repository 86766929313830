<template>
  <!--  -->
  <div class="screen">
    <!-- 3d画布 -->
    <draw ref="draw" :initLoader="initLoader" />
  </div>
</template>
 
<script>
import draw from "@/components/draw/index.vue"; //3d画布
import { objloader,fbxloader } from "@/utils/three/load";
export default {
  components: {
    draw: draw, //3d画布
  },
  methods: {
    // 引入模型文件
    initLoader() {
      objloader('demo',"test").then((tree) => {
        // 加载树木
		console.log(tree.obj);
        this.$refs.draw.scene.add(tree.obj);
      });
	  
	  // fbxloader('kdf',"0").then((tree) => {
	  //   // 加载树木
	  //   this.$refs.draw.scene.add(tree);
	  // });
    },
  },
};
</script>
 
<style lang="less" scoped>
.screen {
  width: 100vw;
  height: 100vh;
}
</style>